import React, { useState } from "react";
import { Col, Row, Table, Container, Badge } from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { SIGNUP_URL } from "../util/constants";

import "./pricing.scss";
import FAQ from "../components/faq";
import { Link } from "gatsby";

const FeatureIcon = ({ supported, supportedIcon }) => {
  const icon = supported ? <i className={`fas ${supportedIcon}`}></i> : null;
  const detail = typeof supported !== "boolean" ? <p>{supported}</p> : null;
  return (
    <div>
      {icon}
      {detail}
    </div>
  );
};

const Feature = ({
  name,
  description,
  basicSupport = false,
  basicSupportedIcon = "fa-check",
  proSupport = false,
  proSupportedIcon = "fa-check",
  enterpriseSupport = true,
  enterpriseSupportedIcon = "fa-check",
}) => {
  return (
    <tr>
      <th>
        <h5>{name}</h5>
        {description}
      </th>
      <td>
        <FeatureIcon
          supported={basicSupport}
          supportedIcon={basicSupportedIcon}
        />
      </td>
      <td>
        <FeatureIcon supported={proSupport} supportedIcon={proSupportedIcon} />
      </td>
      <td>
        <FeatureIcon
          supported={enterpriseSupport}
          supportedIcon={enterpriseSupportedIcon}
        />
      </td>
    </tr>
  );
};

const PriceCallout = ({ monthlyRate, annualRate }) => {
  const annualMonthly = annualRate / 12;
  const formattedMonthly = annualMonthly.toFixed(2).replace(/\.?0+$/, "");
  return (
    <>
      <h6>
        ${formattedMonthly}/month{" "}
        <small className="text-muted">billed annually</small>
        <br />
        <small className="text-muted">
          (or ${monthlyRate}/mo billed monthly)
        </small>
      </h6>
    </>
  );
};

const PriceTable = () => {
  const [showAnnual, setShowAnnual] = useState(true);
  return (
    <>
      <Container>
        <Row className="md-m-25px-b m-45px-b justify-content-center text-center">
          <Col lg={8}>
            <h3 className="h1 m-20px-b p-20px-b theme-after after-50px">
              Digital Menu System Pricing
            </h3>
            <p className="m-0px font-2">
              We pride ourselves on <b>simple, transparent pricing</b> for a
              great product.
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={6} lg={4} className="m-15px-tb">
            <div className="price-table">
              <div className="pt-head">
                <h5>Basic</h5>
              </div>
              <div className="pt-body">
                <PriceCallout annualRate={399} monthlyRate={39} />
                <p>
                  Our famous starter plan for creating an awesome, pro-quality
                  menu.
                </p>
                <ul className="list-type-02">
                  <li>
                    <i className="fas fa-check"></i>
                    <b>TV Menu</b> &mdash; Includes support for a single TV
                    (purchase hardware separately)
                  </li>
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <b>Web &amp; QR Code Menu</b> &mdash; Your menu is always
                    online &amp; up-to-date at your own <em>Taplist.io</em> URL
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    <b>Illustration Generator</b> and{" "}
                    <b>HD Background Library</b> &mdash; Make your menu stand
                    out with our built-in imagery
                  </li>
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <b>Tons of Customizations</b> &mdash; Use and customize our
                    built-in themes
                  </li>
                  <li>
                    <i className="fas fa-check"></i> Your own private{" "}
                    <b>Beverage and Product Database</b> &mdash; Full control of
                    tasting notes and all item details
                  </li>
                  {/* <li>
                    <i className="fas fa-exclamation-circle"></i> Maximum of 32
                    menu items added or changed per month.
                  </li> */}
                </ul>
              </div>
              <div className="pt-footer">
                <a
                  className="m-btn m-btn-theme2nd m-btn-radius"
                  href={SIGNUP_URL}
                >
                  Get Started
                </a>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4} className="m-15px-tb">
            <div className="price-table active">
              <div className="pt-head">
                <h5>Pro</h5>
                {/* <label className="msg">Popular Choice</label> */}
              </div>
              <div className="pt-body">
                <PriceCallout annualRate={588} monthlyRate={59} />
                <p>
                  Our most popular plan for Bars, Breweries, and Restaurants.
                </p>
                <ul className="list-type-02">
                  <li>
                    <i className="fas fa-plus"></i>{" "}
                    <b>Everything in the Basic plan</b>, plus...
                  </li>
                  <li>
                    <i className="fas fa-check"></i> <b>More TVs</b> &mdash;
                    Support for up to four TVs included, for showing your menu
                    across different TVs or rooms
                  </li>
                  <li>
                    <i className="fas fa-check"></i> <b>Print Menus</b> &mdash;
                    Customize and download beautiful PDF menus for printing
                  </li>
                  <li>
                    <i className="fas fa-check"></i> <b>Embedded Web Menu</b>{" "}
                    &mdash; Show your menu on your own web site
                  </li>
                  {/* <li>
                    <i className="fas fa-check"></i> <b>Food &amp; Merch</b>{" "}
                    &mdash; One menu for everything, show food and non-beverage
                    items in the same place
                  </li> */}
                  <li>
                    <i className="fas fa-check"></i> <b>Image Carousel</b>{" "}
                    &mdash; Insert your own ads or promotional images in
                    rotation on your TVs
                  </li>
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <b>Multi-Location Capable</b> &mdash; Manage additional
                    locations from the same login (addl. fee per location)
                  </li>
                  <li>
                    <i className="fas fa-check"></i> <b>Staff Seats</b> &mdash;
                    Invite up to 4 other accounts to manage your menu
                  </li>
                </ul>
              </div>
              <div className="pt-footer">
                <a
                  className="m-btn m-btn-theme2nd m-btn-radius"
                  href={SIGNUP_URL}
                >
                  Get Started
                </a>
              </div>
            </div>
          </Col>
          <Col md={6} lg={4} className="m-15px-tb">
            <div className="price-table active">
              <div className="pt-head">
                <h5>Enterprise</h5>
                {/* <label className="msg">Popular Choice</label> */}
              </div>
              <div className="pt-body">
                <h6>
                  Contact Us{" "}
                  <small className="text-muted">Custom pricing</small>
                </h6>
                <p>
                  For the most demanding and high end deployments, our
                  Enterprise plan is custom tailored to your needs.
                </p>
                <ul className="list-type-02">
                  <li>
                    <i className="fas fa-plus"></i>{" "}
                    <b>Everything in the Pro plan</b>, plus...
                  </li>
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <b>Enterprise Hardware Support</b> &mdash; Integrates with
                    enterprise digital signage platforms
                  </li>
                  <li>
                    <i className="fas fa-check"></i>{" "}
                    <b>Unlimited Staff Seats</b> &mdash; Invite and manage as
                    many additional staff members as you need
                  </li>
                  <li>
                    <i className="fas fa-check"></i> <b>Unlimited TVs</b>{" "}
                    &mdash; Create and customize as many unique TVs as you need
                  </li>
                  <li>
                    <i className="fas fa-check"></i> <b>All Themes</b> &mdash;
                    All web, TV, and print themes are included with all of our
                    Enterprise plans
                  </li>
                  <li>
                    <i className="fas fa-check"></i> <b>Fleet Management</b>{" "}
                    &mdash; Tools and assistance managing a large number of
                    deployed devices
                  </li>
                </ul>
              </div>
              <div className="pt-footer">
                <a
                  className="m-btn m-btn-theme2nd m-btn-radius"
                  href={"mailto:info@taplist.io?subject=Enterprise+plan"}
                >
                  Contact Us
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center text-center">
          <Col lg={8}>
            <p className="font-1">
              All prices are USD, per location. You can upgrade, downgrade, or
              cancel any time through dashboard. We have a very simple{" "}
              <a href="/refund-policy">refund policy</a> and a 100% satisfaction
              guarantee.
            </p>
            <p className="font-1">
              <b>Homebrewer?</b> We are happy to offer{" "}
              <a href="/homebrew">very special discount</a>, <b>only</b> for
              non-commercial customers.
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const PricingPage = () => (
  <Layout>
    <SEO title="Pricing for Digital Beer and Beverage Menus" />
    <section className="section">
      <Row>
        <Col>
          <PriceTable />
        </Col>
      </Row>
      <Row style={{ marginTop: "2em" }}>
        <Col>
          <h3 style={{ textAlign: "center", marginBottom: "2em" }}>
            Plan Comparison
          </h3>
        </Col>
      </Row>
      <Row>
        <Col className="feature-table-container">
          <Table className="feature-table" borderless responsive={"md"} hover>
            <thead>
              <tr>
                <th></th>
                <th>
                  <h5>Basic</h5>
                </th>
                <th>
                  <h5>Pro</h5>
                </th>
                <th>
                  <h5>Enterprise</h5>
                </th>
              </tr>
            </thead>
            <tbody>
              <Feature
                name="Web &amp; QR Menu"
                description={
                  <>
                    Your menu hosted 24/7 on a lightning-fast{" "}
                    <em>Taplist.io</em> page
                  </>
                }
                basicSupport
                proSupport
                enterpriseSupport
              />
              <Feature
                name="TV Menu"
                description={
                  <>Show an instantly-updating TV menu in your business</>
                }
                basicSupport={"1 TV"}
                proSupport={"4 TVs included"}
                enterpriseSupport={"No limit"}
              />
              {/* <Feature
                name="Built-in Themes"
                description={
                  <>TV, Web, and Print themes available for you to customize</>
                }
                basicSupport={"4"}
                proSupport={"12+"}
                enterpriseSupport={"All included"}
              /> */}
              <Feature
                name="Private Beverage Database"
                description={
                  <>
                    Manage your own library of beverages, logos, and
                    descriptions
                  </>
                }
                basicSupport
                proSupport
                enterpriseSupport
              />
              <Feature
                name="Illustration Generator"
                description={
                  <>Use our library of glassware and keg illustrations</>
                }
                basicSupport
                proSupport
                enterpriseSupport
              />
              <Feature
                name={<>Print Menus <Badge pill variant="success">Beta</Badge></>}
                description={
                  <>Customize and download PDFs of your menu for printing</>
                }
                proSupport
                enterpriseSupport
              />{" "}
              <Feature
                name="Embed Mode"
                description={<>Embed your menu into your own web page</>}
                proSupport
                enterpriseSupport
              />
              {/* <Feature
                name="Food &amp; Merch Products"
                description={
                  <>
                    One menu for everything, add and manage non-beverage
                    products
                  </>
                }
                proSupport
                enterpriseSupport
              /> */}
              <Feature
                name="Image Carousels"
                description={
                  <>Upload images, posters, or ads and show them on your TVs</>
                }
                proSupport
                enterpriseSupport
              />
              <Feature
                name="Staff Seats"
                description={
                  <>Invite additional users to manage your account</>
                }
                proSupport={"Up to 4"}
                enterpriseSupport={"No limit"}
              />
              <Feature
                name="Additional Locations"
                description={
                  <>Add and manage additional subscriptions from one account</>
                }
                proSupport={"Up to 4"}
                enterpriseSupport={<>Custom pricing</>}
              />
              <Feature
                name="Platform Integrations"
                description={
                  <>Works with Raydiant and other digital signage platforms</>
                }
                enterpriseSupport
              />
            </tbody>
          </Table>
        </Col>
      </Row>
    </section>
    <section>
      <Row style={{ marginTop: "2em" }}>
        <Col>
          <h3 style={{ textAlign: "center" }}>FAQ</h3>
          <p style={{ textAlign: "center" }}>
            We've prepared some answers to our most frequently-asked questions.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 8, offset: 2 }}>
          <FAQ
            faqItems={[
              {
                question: "Do I need to make an annual commitment?",
                answer: (
                  <>
                    <p>
                      No. We have always been proud to offer month-to-month
                      subscriptions for all of our plans. We do offer a discount
                      for annual commitments, which you can select later and at
                      any time in the dashboard.
                    </p>
                    <p>
                      Many of our customers start month-to-month, then switch to
                      annual to save money after getting comfortable.
                    </p>
                  </>
                ),
              },
              {
                question:
                  "Will Taplist.io place ads or other content on my menu?",
                answer: (
                  <>
                    <p>
                      <b>Absolutely not.</b> Unlike other options out there, our
                      mission is to promote just one brand &amp; one
                      business&mdash;yours, not ours.
                    </p>
                    <p>
                      Taplist.io is not trying to be a social network for beer
                      drinkers. We also do not place links or search boxes to
                      other breweries or retailers on your menu page. It's your
                      menu; it should be your content.
                    </p>
                    <p>
                      Most of our customers call this the most refreshing
                      difference about us.
                    </p>
                  </>
                ),
              },
              {
                question: "Is Taplist.io stable and reliable?",
                answer: (
                  <>
                    <p>
                      Yes. We are very proud of our track record of providing a
                      simple, reliable product. We count hundreds of bars,
                      breweries, and restaurants as satisfied customers, and we
                      have a very low churn rate (customers tend to stick with
                      us).
                    </p>
                    <p>
                      Across all of our customers, our TV app has recorded{" "}
                      <b>millions of hours</b> of continuous usage without a
                      single crash, and our web menu uptime is{" "}
                      <b>&gt;99.999%</b>. Basically, we are rock-solid.
                    </p>
                  </>
                ),
              },
              {
                question:
                  "If I have 2 or more business locations, how does it work?",
                answer: (
                  <>
                    <p>
                      We have many customers managing multiple locations with
                      us!
                    </p>
                    <p>
                      If you have two or more locations, our plans with
                      multi-location support make it easy to manage them from a
                      single login. Each location has its own menu, but your
                      beverage and product database is automatically shared
                      among them.
                    </p>
                    <p>
                      "Per-location pricing" means that each location is charged
                      for an individual subscription, just as if you had set up
                      the accounts separately. All will be billed on a single
                      invoice. As an example, if your plan was $59/month, and
                      you created 3 locations in your account, your monthly
                      charge would be <tt>($59 &times; 3) = $177/mo</tt>.
                    </p>
                  </>
                ),
              },
              {
                question: "Can I get a coupon or discount?",
                answer: (
                  <>
                    <p>
                      Yes. For all of our plans, we offer an automatic discount
                      with an annual commitment. It's a great way to save 10-20%
                      on your costs.
                    </p>
                    <p>
                      For the largest and most sophisticated
                      customers&mdash;those with needs and budgets beyond our
                      Pro plan&mdash;our Enterprise plan is available with
                      custom pricing.
                    </p>
                  </>
                ),
              },
              {
                question: "What happens if I'm not happy?",
                answer: (
                  <>
                    <p>
                      All of our plans come with our risk-free satisfaction
                      guarantee: If you sign up and the service isn't working
                      for you, we'll make it right or refund you. See our{" "}
                      <Link to="/refund-policy">refund policy</Link> for the
                      full details.
                    </p>
                  </>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </section>
  </Layout>
);

export default PricingPage;
